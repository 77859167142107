.snotifyToast__buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border: 1px solid $default-text-color;
  background: $white;
  overflow: auto;
  border-radius: 0 0 6px 6px;
  button {
    position: relative;
    width: 100%;
    border-right: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    border-top: none;
    border-bottom: none;
    background: transparent;
    padding: 8px;
    text-transform: capitalize;
    color: $default-text-color;
    font-weight: 500;
    font-size: 14px;


    &:hover,
    &:focus {
      background: rgba(0,0,0,.1);
      background:$default-text-color;
      color: $white;
      outline: none;
    }

    &:active {
      background: rgba(0,0,0,.15);
    }

    &:last-child {
      border-right: none;
    }

    &:first-child {
      border-left: none;
    }
  }

  &--bold {
    font-weight: 700;
  }
}
