$toast-bg: #fff!default;
$toast-color: #000!default;
$toast-progressBar: #c7c7c7!default;
$toast-progressBarPercentage: #4c4c4c!default;

$border-width: 4px!default;
$simple-border-color: #000!default;
$success-border-color: #4CAF50!default;
$info-border-color: #1e88e5!default;
$warning-border-color: #ff9800!default;
$error-border-color: #f44336!default;
$async-border-color: $info-border-color!default;
$confirm-border-color: #009688!default;
$prompt-border-color: $confirm-border-color!default;

.snotifyToast {
  display: block;
  cursor: pointer;
  background-color: $toast-bg;
  max-height: 300px;
  height: 100%;
  margin: 5px;
  opacity: 0;
  overflow: hidden;
  pointer-events: auto;
  border-radius: 6px;

  &--in {
    animation-name: appear;
  }

  &--out {
    animation-name: disappear;
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    //padding: 5px 65px 5px 15px;
    padding: 15px;
    //min-height: 78px;
    font-size: 14px;
    color: $toast-color;
  }

  &__progressBar {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: $toast-progressBar;

    &__percentage {
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      background-color: $toast-progressBarPercentage;
      max-width: 100%;
    }
  }

  &__title {
    font-size: 1.8em;
    line-height: 1.2em;
    margin-bottom: 5px;
    color: $toast-color;
  }

  &__body {
    font-size: 14px;
    font-weight: 500;
  }

}

.snotifyToast-show {
  transform: translate(0, 0);
  opacity: 1;
}

.snotifyToast-remove {
  max-height: 0;
  overflow: hidden;
  transform: translate(0, 50%);
  opacity: 0;
}

/***************
 ** Modifiers **
 **************/

.snotify-simple {
  background: lighten($simple-border-color, 0%);

  .snotifyToast__inner {
    color: lighten($simple-border-color, 100%);
  }
}

.snotify-success {
  background: lighten($success-border-color, 50%);

  .snotifyToast__inner {
    color: darken($success-border-color, 10%);
  }
}

.snotify-info {
  background: lighten($info-border-color, 40%);

  .snotifyToast__inner {
    color: darken($info-border-color, 10%);
  }
}

.snotify-warning {
  background: lighten($warning-border-color, 40%);

  .snotifyToast__inner {
    color: darken($warning-border-color, 0%);
  }
}

.snotify-error {
  background: lighten($error-border-color, 30%);

  .snotifyToast__inner {
    color: darken($error-border-color, 10%);
  }
}

.snotify-async {
  background: lighten($async-border-color, 50%);

  .snotifyToast__inner {
    color: darken($async-border-color, 10%);
  }
}

.snotify-confirm {
  background: darken($confirm-border-color, 10%);

  .snotifyToast__inner {
    color: darken($confirm-border-color, 50%);
  }

  .snotifyToast__buttons button {
    color: darken($confirm-border-color, 50%);
  }
}

.snotify-prompt {
  background: lighten($prompt-border-color, 0%);

  .snotifyToast__inner {
    color: lighten($prompt-border-color, 80%);
  }

  .snotifyToast__input__label {
    color: lighten($prompt-border-color, 80%);
  }

  .snotifyToast__buttons button {
    color: $white;
  }

  ng-snotify-prompt {
    width: 100%;
  }
}

.snotify-confirm,
.snotify-prompt {
  .snotifyToast__inner {
    padding: 10px 15px;
  }
}

