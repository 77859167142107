
$light-gray: $gray-300;
$medium-gray: $gray-500;
$dark-gray: $gray-900;
$screen-sm-min: 500px;
$screen-md-min: 500px;
$screen-lg-min: 500px;
$font-size-h4: $font-size-base;
$font-size-small: $font-size-base;
$padding-default-vertical: $spacer;

$bs-datetimepicker-timepicker-font-size: 1.2em !default;
$bs-datetimepicker-active-bg: $primary !default;
$bs-datetimepicker-active-color: $white !default;
$bs-datetimepicker-border-radius: 0.5 * $spacer;
$bs-datetimepicker-btn-hover-bg: $light-gray !default;
$bs-datetimepicker-disabled-color: $medium-gray !default;
$bs-datetimepicker-alternate-color: $medium-gray !default;
$bs-datetimepicker-secondary-border-color: #ccc !default;
$bs-datetimepicker-secondary-border-color-rgba: rgba(0, 0, 0, 0.2) !default;
$bs-datetimepicker-primary-border-color: white !default;
$bs-datetimepicker-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !default;

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}


.bootstrap-datetimepicker-widget {
  list-style: none;


  &.dropdown-menu {
    padding: 4px;
    width: 19em;

    &.timepicker-sbs {
      @media (min-width: $screen-sm-min) {
        width: 38em;
      }

      @media (min-width: $screen-md-min) {
        width: 38em;
      }

      @media (min-width: $screen-lg-min) {
        width: 38em;
      }
    }

    &.bottom {
      visibility: visible;
      opacity: 1;
      &:before,
      &:after{
        right: auto;
        left: 12px
      }
    }

    &.top {
      margin-top: auto;
      margin-bottom: -20px;

      &.open{
        margin-top: auto;
        margin-bottom: 5px;
      }
    }



    &.pull-right {
      &:before {
        left: auto;
        right: 6px;
      }

      &:after {
        left: auto;
        right: 7px;
      }
    }
  }

  .list-unstyled {
    margin: 0;
  }

  // a[data-action] {
  //     padding: 6px 0;
  //     border-width: 0;
  // }
  a[data-action] {
    padding: 0;
    margin: 0;
    border-width: 0;
    background-color: transparent;
    color: $primary;
    box-shadow: none;

    &:hover{
      background-color: transparent;

      span{
        background-color: $bs-datetimepicker-btn-hover-bg;
        color: $primary;
      }
    }
  }
  a[data-action]:active {
    box-shadow: none;
  }

  .timepicker-hour, .timepicker-minute, .timepicker-second {
    width: 56px;
    height: 56px;
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
    margin: 3px;
    border-radius: 50%;
  }

  button[data-action] {
    padding: 6px;
  }

  .btn[data-action="incrementHours"]::after {
    @extend .sr-only;
    content: "Increment Hours";
  }

  .btn[data-action="incrementMinutes"]::after {
    @extend .sr-only;
    content: "Increment Minutes";
  }

  .btn[data-action="decrementHours"]::after {
    @extend .sr-only;
    content: "Decrement Hours";
  }

  .btn[data-action="decrementMinutes"]::after {
    @extend .sr-only;
    content: "Decrement Minutes";
  }

  .btn[data-action="showHours"]::after {
    @extend .sr-only;
    content: "Show Hours";
  }

  .btn[data-action="showMinutes"]::after {
    @extend .sr-only;
    content: "Show Minutes";
  }

  .btn[data-action="togglePeriod"]::after {
    @extend .sr-only;
    content: "Toggle AM/PM";
  }

  .btn[data-action="clear"]::after {
    @extend .sr-only;
    content: "Clear the picker";
  }

  .btn[data-action="today"]::after {
    @extend .sr-only;
    content: "Set the date to today";
  }

  .picker-switch {
    text-align: center;
    border-radius: $bs-datetimepicker-border-radius;

    &::after {
      @extend .sr-only;
      content: "Toggle Date and Time Screens";
    }

    td {
      padding: 0;
      margin: 0;
      height: auto;
      width: auto;
      line-height: inherit;

      span {
        line-height: 2.5;
        height: 2.5em;
        width: 100%;
        border-radius: $bs-datetimepicker-border-radius;
        margin: 2px 0px !important;
      }
    }
  }

  table {
    width: 100%;
    margin: 0;


    & td ,
    & th {
      //@extend .animation-transition-fast;
      text-align: center;

    }


    & th {
      //@extend .animation-transition-fast;

      height: 20px;
      line-height: 20px;
      width: 20px;

      &.picker-switch {
        width: 145px;
      }

      &.disabled,
      &.disabled:hover {
        background: none;
        color: $bs-datetimepicker-disabled-color;
        cursor: not-allowed;
      }

      &.prev span,
      &.next span{
        border-radius: $bs-datetimepicker-border-radius;
        height: 27px;
        width: 27px;
        line-height: 28px;
        font-size: 12px;
        border-radius: 50%;
        text-align: center;

      }

      &.prev::after {
        @extend .sr-only;
        content: "Previous Month";
      }

      &.next::after {
        @extend .sr-only;
        content: "Next Month";
      }

      &.dow{
        text-align: center;
        border-bottom: 1px solid $light-gray;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $dark-gray;
        font-weight: $font-weight-normal;
        padding-bottom: 5px;
        padding-top: $padding-default-vertical;
      }
    }

    & thead tr:first-child th{
      cursor: pointer;

      &:hover span,
      &.picker-switch:hover{
        background: $bs-datetimepicker-btn-hover-bg;
      }
    }

    & td{

      border-radius: $bs-datetimepicker-border-radius;

      height: 54px;
      line-height: 54px;
      width: 54px;
      text-align: center;


      &.cw{
        font-size: .8em;
        height: 20px;
        line-height: 20px;
        color: $bs-datetimepicker-alternate-color;
      }

      &.day  {
        height: 30px;
        line-height: 30px;
        width: 15px;
        text-align: center;
        padding: 0px;
        border-radius: 50%;
      }

      &.minute ,
      &.hour{
        border-radius: 50%;
      }

      &.day:hover ,
      &.hour:hover ,
      &.minute:hover,
      &.second:hover  {
        background: $bs-datetimepicker-btn-hover-bg;
        cursor: pointer;
      }

      &.old ,
      &.new {
        color: $bs-datetimepicker-alternate-color;
      }

      &.today {
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          border: 0 0 7px 7px solid transparent;
          border-bottom-color: $bs-datetimepicker-active-bg;
          border-top-color: $bs-datetimepicker-secondary-border-color-rgba;
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }

      &.active ,
      &.active:hover  {
        background-color: $bs-datetimepicker-active-bg;
        color: $bs-datetimepicker-active-color;

      }

      &.active.today:before {
        border-bottom-color: #fff;
      }

      &.disabled ,
      &.disabled:hover  {
        background: none;
        color: $bs-datetimepicker-disabled-color;
        cursor: not-allowed;
      }

      span {
        //@extend .animation-transition-fast;

        display: inline-block;
        width: 56px;
        height: 56px;
        line-height: 56px;
        margin: 3px 3px;
        cursor: pointer;
        border-radius: 50%;
        text-align: center;

        &:hover {
          background: $bs-datetimepicker-btn-hover-bg;
        }

        &.active {
          background-color: $bs-datetimepicker-active-bg;
          color: $bs-datetimepicker-active-color;
        }

        &.old {
          color: $bs-datetimepicker-alternate-color;
        }

        &.disabled,
        &.disabled:hover {
          background: none;
          color: $bs-datetimepicker-disabled-color;
          cursor: not-allowed;
        }
      }
    }
  }

  .timepicker-picker,
  .timepicker-hours,
  .timepicker-minutes{
    span{
      border-radius: 50% !important;
    }
  }

  &.usetwentyfour {
    td.hour {
      height: 27px;
      line-height: 27px;
    }
  }
  .datepicker-decades{
    table{
      td{
        span.active{
          background-color: transparent;
          color: rgba(0,0,0, 0.87);
        }
      }
    }
  }
}

.input-group.date {
  & .input-group-addon {
    cursor: pointer;
  }
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th{
  padding: 1px;
}
