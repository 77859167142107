
.snotifyToast {
  &.snotify-error {
    .snotifyToast__buttons {
      button {
        background-color: #ff095c;
        color: #FFFFFF;

        &:hover,
        &:focus {
          background-color: rgba(255, 9, 93, 0.69);
          outline: none;
        }
      }
    }
  }

  .snotifyToast__buttons {
    border: none;

    button {
      text-transform: none !important;
    }
  }
}
